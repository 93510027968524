import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { resetPasswordAnonymous } from "../api/authentication";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await resetPasswordAnonymous({ email });
      toast.success('Please check your email for password reset instructions');
    } catch (error) {
      toast.error('Error resetting password');
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
      <div className="w-full max-w-md px-4 py-12">
        <h2 className="mb-6 text-3xl font-bold text-center text-gray-900">
          Forgot your password?
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm">
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
              placeholder="Email address"
              value={email}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col space-y-4">
            <button
              type="submit"
              className="flex justify-center w-full rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            >
              Reset Password
            </button>
            <div className="text-xs text-center text-gray-600">
              <div>Need to sign up first?<Link className="pl-1 text-emerald-600 hover:underline" to="/signup"> Sign up</Link></div>
              <div>Remember your password?<Link className="pl-1 text-emerald-600 hover:underline" to="/signin"> Sign in</Link></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
