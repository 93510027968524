import React, { useEffect, useState } from 'react';
import { readOrg } from '../api/organizations';
import { useUser } from "../contexts/user";

export default function AddMemberToTeam({ team, onSubmit, onCancel }) {
  const user = useUser();
  const [org, setOrg] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const response = await readOrg({
          token: user.token,
          org_id: team.organization,
        });
        setOrg(response.data);

        // Initialize selectedMembers array
        const initialSelectedMembers = team.members.map(member => member._id);
        setSelectedMembers(initialSelectedMembers);
      } catch (error) {
        console.error('Failed to fetch org', error);
      }
    };

    fetchOrg();
  }, [user, team.organization, team.members]);

  const handleCheckboxChange = (memberId) => {
    if (selectedMembers.includes(memberId)) {
      setSelectedMembers(prevSelectedMembers => prevSelectedMembers.filter(id => id !== memberId));
    } else {
      setSelectedMembers(prevSelectedMembers => [...prevSelectedMembers, memberId]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(selectedMembers);
  };

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">Add Members to {team.name}</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-600">
              <tbody className="divide-y divide-gray-600">
                {org?.members?.map((member) => (
                  <tr key={member.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-200">
                      {member.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <input
                        type="checkbox"
                        checked={selectedMembers.includes(member._id)}
                        onChange={() => handleCheckboxChange(member._id)}
                        className="form-checkbox h-5 w-5 text-indigo-600 rounded"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              type="button"
              onClick={onCancel}
              className="text-red-500 hover:underline py-2 px-4 text-sm font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex items-center text-gray-200 hover:underline py-2 px-4 text-sm font-medium"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}