import React, { useState } from 'react';
import { useUser } from '../contexts/user';
import { useParams } from 'react-router-dom';
import { createSurvey } from '../api/surveys';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

const SurveyForm = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const { org_id } = useParams();
  const [formData, setFormData] = useState({
    title: '',
    start_date: '',
    questions: []
  });

  const handleInputChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('question_')) {
      const [field, idx] = name.split('_');
      const newQuestions = [...formData.questions];
      newQuestions[idx] = { ...newQuestions[idx], [field]: type === 'checkbox' ? checked : value };
      setFormData({ ...formData, questions: newQuestions });
    } else {
      setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    }
  };

  const addQuestion = () => {
    setFormData({
      ...formData,
      questions: [...formData.questions, {
        text: '',
        type: 'text',
        subject: 'self',
        order: formData.questions.length + 1
      }]
    });
  };

  const removeQuestion = (index) => {
    const newQuestions = formData.questions.filter((_, i) => i !== index);
    setFormData({ ...formData, questions: newQuestions.map((q, i) => ({ ...q, order: i + 1 })) });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createSurvey({ token, org_id, survey: { ...formData } });
      toast.success('Survey created successfully');
      navigate(`/org/${org_id}/surveys`);
    } catch (error) {
      console.log('what error', { error })
      toast.error('Failed to create survey');
    }
  };

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">New Survey</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-white">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-white bg-gray-600"
              />
            </div>
            <div>
              <label htmlFor="start_date" className="block text-sm font-medium text-white">Start Date</label>
              <input
                type="date"
                id="start_date"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-white bg-gray-600"
              />
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-center mb-4 pb-2 border-b border-white">
              <h2 className="text-xl font-semibold text-white">Questions</h2>
              <button
                type="button"
                onClick={addQuestion}
                className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
                aria-label="Add question"
              >
                <PlusIcon className="h-6 w-6" />
              </button>
            </div>

            {formData.questions.map((question, index) => (
              <div key={index} className="space-y-2 border-b border-gray-200 border-dotted pb-4 mb-4 relative">
                <button
                  type="button"
                  onClick={() => removeQuestion(index)}
                  className="absolute top-0 right-0 text-red-500 hover:text-red-700 focus:outline-none"
                  aria-label="Remove question"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
                <input
                  type="text"
                  name={`question_text_${index}`}
                  value={question.text}
                  onChange={(e) => handleInputChange(e, index)}
                  placeholder="Question text"
                  required
                  className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-white bg-gray-600"
                />
                <div className="flex space-x-4">
                  <select
                    name={`question_type_${index}`}
                    value={question.type}
                    onChange={(e) => handleInputChange(e, index)}
                    className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-white bg-gray-600"
                  >
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="boolean">Yes/No</option>
                    <option value="likert">Likert</option>
                  </select>
                  <select
                    name={`question_subject_${index}`}
                    value={question.subject}
                    onChange={(e) => handleInputChange(e, index)}
                    className="mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-white bg-gray-600"
                  >
                    <option value="self">Self</option>
                    <option value="peers">Peers</option>
                  </select>
                  <input
                    type="number"
                    name={`question_order_${index}`}
                    value={question.order}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder="Order"
                    className="mt-1 block w-24 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-white bg-gray-600"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-end">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="text-red-500 hover:underline py-2 px-4 text-sm font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex items-center text-gray-200 hover:underline py-2 px-4 text-sm font-medium"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SurveyForm;