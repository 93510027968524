import { URL } from ".";

const readUserCommits = async ({ token, user_id }) => {
  try {
    const response = await fetch(`${URL}/users/${user_id}/commits`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    console.log('Unable to read user commmits', error);
  }
}
export { readUserCommits }
