import React from "react"
import { Link } from 'react-router-dom'
const stage = process.env.REACT_APP_STAGE


export default function HomePage() {
  console.log('stage', stage)

  return (
    <>
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-700">
        <div className="flex flex-col justify-center items-center">
          <img src="rocket.png" alt="Rocket" className="h-36 mb-8 bounce" />
          <Link
            className="rounded-md  px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
            to="/signin"
          >
            Sign In
          </Link>
        </div>
      </div>
    </>
  )
}
