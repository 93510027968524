import React from 'react';

export default function Loading() {
  return (
    <div className="min-h-screen bg-gray-700 flex justify-center items-center">
      <div className="animate-bounce">
        <img src="rocket.png" alt="Loading..." className="h-16 w-16" />
      </div>
    </div>
  );
}
