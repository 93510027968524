import React, { useEffect, useState } from "react"
import { useUser } from "../contexts/user";
import { readOrgTeams } from '../api/organizations'
import { createUser, readUser, updateUser } from '../api/users'
import Loading from "../components/Loading";
import { useNavigate, useParams } from 'react-router-dom';

export default function MemberForm() {
  const user = useUser();
  const navigate = useNavigate();

  const { user_id } = useParams();
  const isUpdating = !!user_id;

  const [teams, setTeams] = useState([])
  const [loading, setLoading] = useState(false)

  const [memberData, setMemberData] = useState({
    name: '',
    email: '',
    gitlab_email: '',
    title: 'L1',
    role: 'member',
    team: null
  });

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true)
      const { token } = user;
      const response = await readUser({ token, user_id })
      if (!response) return;
      console.log('ud: ', response)
      const { data } = response;
      setMemberData({
        name: data.name,
        email: data.email,
        gitlab_email: data.gitlab_email,
        title: data.title,
        role: data.organization_role,
        team: data.team || null
      })

      setLoading(false)
    }

    const fetchTeams = async () => {
      setLoading(true)
      const { token, id, organization } = user;
      const response = await readOrgTeams({ token, user_id: id, org_id: organization })
      if (!response) return;
      setTeams([...response.data])
      setLoading(false)
    }

    fetchTeams()
    isUpdating && fetchUser()
  }, [user, user_id, isUpdating])

  const handleChange = (e) => {
    if (e.target.value === 'None') {
      return setMemberData({ ...memberData, [e.target.name]: null })
    }
    setMemberData({ ...memberData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, gitlab_email, title, role, team } = memberData;
    const { token, organization } = user;
    try {
      if (isUpdating) {
        await updateUser({
          token, user_id, updates: {
            name,
            email,
            gitlab_email,
            title,
            team,
            organization_role: role
          }
        })
      } else {
        await createUser({ name, email, gitlab_email, title, team, organization_role: role, token, org_id: organization })
      }

      navigate(-1)
    } catch (error) {
      console.error('Failed to create/update user', error);
    }
  }

  if (loading) {
    return < Loading />
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl font-bold mb-4">{isUpdating ? 'Edit Member' : 'New Member'}</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-6">
            {/* Name Input */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-200">
                Name
              </label>
              <input
                id="name"
                name="name"
                onChange={handleChange}
                type="text"
                value={memberData.name}
                autoComplete="name"
                className="mt-2 block w-full border-0 border-b-2 border-white bg-transparent py-1.5 text-gray-200 placeholder:text-gray-400 focus:border-indigo-600 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              />
            </div>

            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200">
                Email address
              </label>
              <input
                id="email"
                name="email"
                value={memberData.email}
                type="email"
                onChange={handleChange}
                autoComplete="email"
                className="mt-2 block w-full border-0 border-b-2 border-white bg-transparent py-1.5 text-gray-200 placeholder:text-gray-400 focus:border-indigo-600 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              />
            </div>
            {/* Email Input */}
            <div>
              <label htmlFor="gitlab_email" className="block text-sm font-medium leading-6 text-gray-200">
                Gitlab Email address
              </label>
              <input
                id="gitlab_email"
                name="gitlab_email"
                value={memberData.gitlab_email}
                type="email"
                onChange={handleChange}
                autoComplete="gitlab_email"
                className="mt-2 block w-full border-0 border-b-2 border-white bg-transparent py-1.5 text-gray-200 placeholder:text-gray-400 focus:border-indigo-600 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              />
            </div>

            {/* Role Select */}
            <div>
              <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-200">
                Organization Role
              </label>
              <select
                id="role"
                name="role"
                autoComplete="role"
                onChange={handleChange}
                value={memberData.role}
                className="mt-2 block w-full border-0 border-b-2 border-white bg-transparent py-1.5 text-gray-200 focus:border-indigo-600 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              >
                <option>member</option>
                <option>admin</option>
                <option>owner</option>
              </select>
            </div>

            {/* Title Select */}
            <div>
              <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-200">
                Title
              </label>
              <select
                id="title"
                name="title"
                onChange={handleChange}
                autoComplete="title"
                value={memberData.title}
                className="mt-2 block w-full border-0 border-b-2 border-white bg-transparent py-1.5 text-gray-200 focus:border-indigo-600 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              >
                <option>Intern</option>
                <option>L1</option>
                <option>L2</option>
                <option>L3</option>
                <option>TD</option>
                <option>Staff</option>
                <option>SRE</option>
                <option>SysAdmin</option>
                <option>Manager</option>
              </select>
            </div>

            {/* Team Select */}
            <div>
              <label htmlFor="team" className="block text-sm font-medium leading-6 text-gray-200">
                Team
              </label>
              <select
                id="team"
                name="team"
                onChange={handleChange}
                autoComplete="team"
                value={memberData.team}
                className="mt-2 block w-full border-0 border-b-2 border-white bg-transparent py-1.5 text-gray-200 focus:border-indigo-600 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              >
                {teams.map(team => (
                  <option key={team._id} value={team._id}>{team.name}</option>
                ))}
                <option key={null} value={null}>None</option>
              </select>
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="text-red-500 hover:underline py-2 px-4 text-sm font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex items-center text-gray-200 hover:underline py-2 px-4 text-sm font-medium"
            >
              {isUpdating ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}