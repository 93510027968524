import { URL } from ".";
import toast from 'react-hot-toast';
import { checkResponseErrors } from "../utils/apiUtils";

const createTeam = async ({ token, name }) => {
  try {
    const response = await fetch(`${URL}/teams`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        name
      })
    });

    // FIXME - not sure on implementation, want a DRY way to check for common errors...
    checkResponseErrors(response);

    toast.success('Team created');
    return await response.json();
  } catch (error) {
    toast.error('Unable to create team');
  }
}
const readTeam = async ({ token, team_id }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    console.log(error)
    toast.error('Unable to read team');
  }
}
const updateTeam = async ({ token, team_id, updates }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        updates
      })

    });

    // FIXME - not sure on implementation, want a DRY way to check for common errors...
    checkResponseErrors(response);

    toast.success('Team updated');

    return await response.json();
  } catch (error) {
    toast.error('Unable to update team');
  }
}
const deleteTeam = async ({ token, team_id }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });

    // FIXME - not sure on implementation, want a DRY way to check for common errors...
    checkResponseErrors(response);

    toast.success('Team deleted');

    return await response.json();
  } catch (error) {
    toast.error('Unable to delete team');
  }
}


export { createTeam, readTeam, updateTeam, deleteTeam }