import React, { useEffect, useState, Fragment } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useUser, useUserDispatch } from '../contexts/user';
// import { readTeam } from '../api/teams';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const initNavigation = (user, teams = []) => {

  let baseNav = [
    { name: 'Profile', href: `/org/${user.organization}/profile/${user.id}` },
  ];

  if (user?.organization_role !== 'member') {
    return [
      ...baseNav,
      { name: 'Teams', href: `/org/${user?.organization}/teams` },
      { name: 'Members', href: `/org/${user?.organization}/members` },
      { name: 'Surveys', href: `/org/${user?.organization}/surveys` },
      { name: 'Org', href: `/org/${user?.organization}` },

    ]
  } else {
    return [
      ...baseNav,
      {
        name: (!!user?.team ? `Team` : `Teams`),
        href: (!!user?.team ? `/org/${user.organization}/teams/${user?.team}` : `/org/${user.organization}/teams`)
      }
    ]

  }

};


export default function Navbar() {
  const user = useUser();
  const dispatch = useUserDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [nav, setNav] = useState(() => initNavigation(user));

  useEffect(() => {

    // might be nice to use to show teams in navbar when we have teams
    // const fetchTeams = async (user) => {
    //   try {
    //     const response = await readTeam({
    //       token: user.token,
    //       team_id: user.team,
    //     });
    //     if (response.ok) {
    //       setNav(initNavigation(user, response.data));
    //     } else {
    //       setNav(initNavigation(user));
    //     }
    //   } catch (error) {
    //     console.error('Failed to fetch teams', error);
    //   }
    // };

    if (user) {
      setNav(initNavigation(user))
    }
  }, [user]);

  const signout = () => {
    dispatch({ type: 'deleted', user: null });
    navigate('/');
  };

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white md:hidden">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <img src="rocket.png" alt="Rocket" className="h-10 mr-4" />
                  <h1 className='text-white font-bold'>SpaceBurd</h1>
                </div>
                <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {nav.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={() =>
                        classNames(
                          (location.pathname === item.href)
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )
                      }
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="hidden md:flex md:flex-shrink-0 md:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <Menu.Button className="flex text-sm rounded-full bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <UserIcon className="h-6 w-6 rounded-full text-white" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={signout}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => navigate('/settings')}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                              )}
                            >
                              Reset password
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {nav.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={NavLink}
                  to={item.href}
                  className={() =>
                    classNames(
                      (location.pathname === item.href)
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )
                  }
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}