import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/user";
import { readSurvey } from '../api/surveys';
import Loading from '../components/Loading';
import { useParams } from 'react-router-dom';

export default function SurveyPage() {
  const user = useUser();
  const { survey_id } = useParams();
  const [survey, setSurvey] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchSurvey = async () => {
        setLoading(true);
        try {
          const response = await readSurvey({
            token: user.token,
            survey_id
          });

          if (response) {
            const survey = response.data;
            setSurvey(survey);
          }
        } catch (error) {
          console.error('Failed to fetch survey', error);
        } finally {
          setLoading(false);
        }
      };

      fetchSurvey();
    }
  }, [user, survey_id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">Survey Details</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        {survey ? (
          <div className="space-y-2">
            <div className="flex justify-between items-center py-2">
              <span className="text-gray-200 text-xl">{survey.title}</span>
            </div>
            <div className="flex justify-between items-center py-2">
              <span className="text-gray-400">Status:</span>
              <span className="text-gray-200">{survey.status}</span>
            </div>
            {/* Add more survey details here */}
          </div>
        ) : (
          <p className="text-center">No survey found.</p>
        )}
      </div>
    </div>
  );
}