import React, { useEffect, useRef } from 'react'
import { HashRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'
import { UserProvider, useUser } from './contexts/user';
import { Toaster } from 'react-hot-toast';

import WelcomePage from './pages/Welcome.js'
// import HomePage from './pages/Home'
import ProfilePage from './pages/Profile'
import AdminPage from './pages/Admin'
import OrgPage from './pages/Org'
import SurveysPage from './pages/Surveys'
import SurveyPage from './pages/Survey'
import TeamsPage from './pages/Teams.js'
import TeamPage from './pages/Team.js'
import AuthForm from './components/AuthForm'
import ForgotPassword from './components/ForgotPassword'
import Navbar from './components/Nav'
import RequireAuth from './middleware/requireAuth';
import RequireAdmin from './middleware/requireAdmin';
import SettingsPage from './pages/Settings'
// import TeamForm from './components/TeamForm'
// import TeamList from './components/TeamList'
import MembersPage from './pages/Members'
// import MemberForm from './components/MemberForm'
import AddMemberToTeam from './components/AddMemberToTeam'
import MemberForm from './pages/MemberForm.js';

function App() {
  return (
    <UserProvider>
      <Toaster />
      <Router>
        <Content />
      </Router>
    </UserProvider >
  );
}

function Content() {
  const user = useUser();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate)

  useEffect(() => {
    if (!!user) {
      navigateRef.current(`/org/${user.organization}/profile/${user.id}`)
    }
  }, [user])
  return (
    <>
      {!!user?.token
        ? <>
          <RequireAuth>
            <Navbar />
            <Routes>
              <Route path="/org/:org_id">
                <Route index element={<OrgPage />} />
                <Route path="profile/:user_id">
                  <Route index element={<ProfilePage />} />
                  <Route path="update" element={
                    <RequireAdmin>
                      <MemberForm />
                    </RequireAdmin>
                  } />
                </Route>
                <Route path="teams">
                  <Route index element={<TeamsPage />} />
                  <Route path=":team_id" element={<TeamPage />} />
                  <Route path=":team_id/new" element={
                    <RequireAdmin>
                      <AddMemberToTeam />
                    </RequireAdmin>
                  } />
                </Route>
                <Route path="members">
                  <Route index element={<MembersPage />} />
                  <Route path=":user_id/edit" element={
                    <RequireAdmin>
                      <MemberForm />
                    </RequireAdmin>
                  } />
                </Route>
                <Route path="surveys">
                  <Route index element={<SurveysPage />} />
                  <Route path=":survey_id" element={<SurveyPage />} />
                </Route>
                <Route path="admin" element={
                  <RequireAdmin>
                    <AdminPage />
                  </RequireAdmin>
                } />
                <Route path="settings" element={
                  <RequireAdmin>
                    <SettingsPage />
                  </RequireAdmin>
                } />
              </Route>
            </Routes>
          </RequireAuth>
        </>
        :
        <Routes>
          <Route exact path="/signup" element={<AuthForm form="signup" buttonText="Sign Up!" heading="Sign up to get started" />} />
          <Route exact path="/signin" element={<AuthForm form="signin" buttonText="Sign In!" heading="Sign in to your account" />} />
          <Route exact path="/forgot_password" element={<ForgotPassword form="signin" buttonText="Sign In!" heading="Sign in to your account" />} />
          <Route path="*" element={<WelcomePage />}></Route>
        </Routes>
      }
    </>
  );
}

export default App;