import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/user";
import { useParams, Link } from 'react-router-dom';
// import { deleteUser } from '../api/users';
import { readTeam, updateTeam } from '../api/teams';
import Loading from '../components/Loading';
// import { PlusIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import AddMemberToTeam from '../components/AddMemberToTeam';

export default function TeamPage() {
  const user = useUser();
  const { org_id, team_id } = useParams();
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddMember, setShowAddMember] = useState(false);


  useEffect(() => {
    const fetchTeam = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await readTeam({
          token: user.token,
          team_id,
        });
        if (response) {
          setTeam({ ...response.data });
        }
      } catch (error) {
        console.error('Error fetching team:', error);
        setError('Failed to load team data');
      } finally {
        setLoading(false);
      }
    };

    if (user && team_id) {
      fetchTeam();
    }
  }, [user, team_id]);

  // const handleEditMember = (memberId) => {
  //   // Implement edit functionality
  //   console.log(`Edit member with id: ${memberId}`);
  // };

  // const handleDeleteMember = async (memberId) => {
  //   try {
  //     const response = await deleteUser({
  //       token: user.token,
  //       team_id,
  //       member_id: memberId,
  //     });
  //     if (response.ok) {
  //       setTeam({
  //         ...team,
  //         members: team.members.filter(member => member._id !== memberId)
  //       });
  //     } else {
  //       throw new Error('Failed to delete team member');
  //     }
  //   } catch (error) {
  //     console.error('Error deleting team member:', error);
  //     setError('Failed to delete team member');
  //   }
  // };

  // const handleAddMember = () => {
  //   setShowAddMember(true);
  // };

  const handleAddMemberSubmit = async (memberIds) => {
    try {
      await updateTeam({
        token: user.token,
        team_id: team._id,
        updates: { members: memberIds }
      })
      setShowAddMember(false);
    } catch (error) {
      console.error('Error adding team member:', error);
      setError('Failed to add team member');
    }
  };

  if (loading) return <Loading />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!team) return <div className="text-white">No team found</div>;

  if (showAddMember) {
    return <AddMemberToTeam
      team={team}
      onSubmit={handleAddMemberSubmit}
      onCancel={() => setShowAddMember(false)}
    />;
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">{team.name}</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        <h2 className="text-xl font-semibold mb-2">Team Members</h2>
        <div className="space-y-2">
          {team.members && team.members.length > 0 ? (
            team.members.map(member => (
              <div key={member._id} className="flex justify-between items-center py-2">
                <Link to={`/org/${org_id}/profile/${member._id}`} className="text-gray-200 hover:underline cursor">
                  {member.name}
                </Link>
              </div>
            ))
          ) : (
            <p className="text-center">No team members found. Add Some!</p>
          )}
        </div>
      </div>
    </div>
  );
}