import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/user";
import { readOrgSurveys } from '../api/surveys';
import Loading from '../components/Loading';
import { Link, useParams } from 'react-router-dom';
import { PlusIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import SurveyForm from '../components/SurveyForm';

// Dropdown component
const Dropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-4 py-2 text-lg font-medium text-left text-white bg-gray-600 rounded-lg hover:bg-gray-500 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
      >
        <span>{title}</span>
        <ChevronDownIcon
          className={`${isOpen ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
        />
      </button>
      {isOpen && (
        <div className="mt-2 p-4 bg-gray-800 rounded-lg">
          {children}
        </div>
      )}
    </div>
  );
};

export default function SurveysPage() {
  const user = useUser();
  const { org_id } = useParams();
  const [pastSurveys, setPastSurveys] = useState([]);
  const [activeSurveys, setActiveSurveys] = useState([]);
  const [scheduledSurveys, setScheduledSurveys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSurveyForm, setShowSurveyForm] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchSurveys = async () => {
        setLoading(true);
        try {
          const response = await readOrgSurveys({
            token: user.token,
            org_id: user.organization,
          });
          if (response) {
            const allSurveys = response.data;
            setPastSurveys(allSurveys.filter(survey => survey.status === 'complete'));
            setActiveSurveys(allSurveys.filter(survey => survey.status === 'active'));
            setScheduledSurveys(allSurveys.filter(survey => survey.status === 'inactive'));
          }
        } catch (error) {
          console.error('Failed to fetch surveys', error);
        } finally {
          setLoading(false);
        }
      };

      fetchSurveys();
    }
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  if (showSurveyForm) {
    return <SurveyForm onSurveySaved={() => setShowSurveyForm(false)} onCancel={() => setShowSurveyForm(false)} />;
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-2xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Surveys</h1>
          <button
            onClick={() => setShowSurveyForm(true)}
            className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
            aria-label="Create new survey"
          >
            <PlusIcon className='h-6 w-6' />
          </button>
        </div>

        <Dropdown title="Past Surveys">
          <div className="space-y-2">
            {pastSurveys.length > 0 ? (
              pastSurveys.map(survey => (
                <div key={survey._id} className="flex justify-between items-center py-2">
                  <Link to={`/org/${org_id}/surveys/${survey._id}`} className="text-gray-200 hover:text-gray-300 hover:underline">
                    {survey.title}
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-center">No past surveys found.</p>
            )}
          </div>
        </Dropdown>

        <Dropdown title="Active Surveys">
          <div className="space-y-2">
            {activeSurveys.length > 0 ? (
              activeSurveys.map(survey => (
                <div key={survey._id} className="flex justify-between items-center py-2">
                  <Link to={`/org/${org_id}/surveys/${survey._id}`} className="text-gray-200 hover:text-gray-300 hover:underline">
                    {survey.title}
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-center">No active surveys found.</p>
            )}
          </div>
        </Dropdown>

        <Dropdown title="Scheduled Surveys">
          <div className="space-y-2">
            {scheduledSurveys.length > 0 ? (
              scheduledSurveys.map(survey => (
                <div key={survey._id} className="flex justify-between items-center py-2">
                  <Link to={`/org/${org_id}/surveys/${survey._id}`} className="text-gray-200 hover:text-gray-300 hover:underline">
                    {survey.title || 'Untitled Survey'}
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-center">No scheduled surveys found.</p>
            )}
          </div>
        </Dropdown>
      </div>
    </div>
  );
}