export const checkResponseErrors = (response) => {
  if (!response.ok) {
    if (response.status === 404) {
      throw new Error('Resource not found');
    } else if (response.status === 500) {
      throw new Error('Internal server error');
    } else {
      throw new Error(`Unexpected error: ${response.status} ${response.statusText}`);
    }
  }
};
