import React from "react";
import { useUser } from "../contexts/user";
import { Navigate } from "react-router-dom";

const RequireAdmin = ({ children }) => {
  const user = useUser();

  if (user?.organization_role === 'member') {
    return <Navigate replace={true} to={`/org/${user.organization}/profile/${user.id}`} />
  }

  return children;
}

export default RequireAdmin;