import { URL } from ".";
import toast from 'react-hot-toast';

const createUser = async ({ token, name, email, title, team, organization_role, org_id }) => {
  try {
    const response = await fetch(`${URL}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        name,
        email,
        title,
        team,
        organization_role,
        organization: org_id
      })
    });
    toast.success('User created');
    return await response.json();
  } catch (error) {
    toast.error('Unable to create user');
  }
}
const readUser = async ({ token, user_id }) => {
  try {
    const response = await fetch(`${URL}/users/${user_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    console.log('Unable to read user', error);
  }
}
const updateUser = async ({ token, user_id, updates }) => {
  try {
    const response = await fetch(`${URL}/users/${user_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        updates
      })
    });
    toast.success('User updated');
    return await response.json();
  } catch (error) {
    toast.error('Unable to update user');
  }
}

const deleteUser = async ({ token, user_id }) => {
  try {
    const response = await fetch(`${URL}/users/${user_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    console.log('Unable to delete user', error);
  }
}



export { createUser, readUser, updateUser, deleteUser }