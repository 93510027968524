import React, { useState, useEffect } from "react";
import { useUser } from "../contexts/user";
import { createTeam, updateTeam } from '../api/teams';
import { PlusIcon } from '@heroicons/react/24/outline';

export default function TeamForm({ initialTeam, onTeamSaved, onCancel }) {
  const user = useUser();
  const [teamData, setTeamData] = useState({
    name: "",
  });

  useEffect(() => {
    if (initialTeam) {
      setTeamData(initialTeam);
    }
  }, [initialTeam]);

  const handleChange = (e) => {
    setTeamData({ ...teamData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = teamData;
    const { token, organization } = user;
    try {
      let result;
      if (initialTeam) {
        result = await updateTeam({ team_id: initialTeam._id, updates: { name }, token });
      } else {
        result = await createTeam({ name, token, org_id: organization });
      }
      
      onTeamSaved(result.data);
    } catch (error) {
      console.error('Failed to save team', error);
      // Handle error (e.g., show error message)
    }
  };

  const isEditMode = !!initialTeam;

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl font-bold mb-4">{isEditMode ? 'Edit Team' : 'Create New Team'}</h1>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-200">
              Team Name:
            </label>
            <input
              id="name"
              name="name"
              value={teamData.name}
              onChange={handleChange}
              type="text"
              autoComplete="name"
              className="mt-2 block w-full border-0 border-b-2 border-white bg-transparent py-1.5 text-gray-200 placeholder:text-gray-400 focus:border-indigo-600 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={onCancel}
              type="button"
              className="text-gray-200 hover:text-gray-300 py-2 px-4 text-sm font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex items-center text-gray-200 hover:text-gray-300 py-2 px-4 text-sm font-medium"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              {isEditMode ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}