import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/user";
import { useParams, Link } from 'react-router-dom';
import { readUser } from '../api/users';
import { readUserCommits } from '../api/commits';
import Loading from '../components/Loading';
import moment from 'moment';
import { PencilIcon } from '@heroicons/react/24/outline';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function ProfilePage() {
  const user = useUser();
  const { user_id } = useParams();
  const [profile, setProfile] = useState(null);
  const [commits, setCommits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user_is_admin = user.organization_role !== 'member';

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await readUser({
          token: user.token,
          user_id,
        });
        if (response) {
          setProfile({ ...response.data });
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };

    const fetchCommits = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await readUserCommits({
          token: user.token,
          user_id,
        });
        if (response) {
          console.log('commits', response.data);

          setCommits(response?.data?.commits);
        }
      } catch (error) {
        console.error('Error fetching user commits:', error);
        setError('Failed to load user commits');
      } finally {
        setLoading(false);
      }
    }

    if (user && user_id) {
      fetchProfile();
      fetchCommits();
    }
  }, [user, user_id]);

  // const handleEditMember = (memberId) => {
  //   // Implement edit functionality
  //   console.log(`Edit member with id: ${memberId}`);
  // };

  if (loading) return <Loading />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!profile) return <div className="text-white">No profile found</div>;

  // Calculate the rolling 7-day commit count
  const commitsByDay = commits
    .filter(commit => moment(commit.created_at).isAfter(moment().subtract(7, 'days')))
    .reduce((acc, commit) => {
      const date = moment(commit.created_at).toISOString().slice(0, 10);
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date]++;
      return acc;
    }, {});

  const commitData = Object.keys(commitsByDay)
    .map(date => ({
      date,
      label_date: moment(date).format('MM/DD'),
      commits: commitsByDay[date]
    }))
    .slice(-7)
    .sort((a, b) => moment(a.date).isAfter(moment(b.date))); // Sort by date in ascending order

  const sortedCommits = [...commits].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));


  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-2xl mx-auto">
        <div className="flex justify-between align-center">
          <h1 className="text-xl font-bold ">{profile.name}</h1>
          {user_is_admin && (
            <Link
              className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
              aria-label="Edit profile"
              to={`update`}
            >
              <PencilIcon className='h-4 w-4' />
            </Link>
          )}
        </div>
        <div className="w-full border-t border-gray-600 mb-4"></div>
        <div className="mb-4">
          <p className="text-lg font-medium">Email: {profile.email}</p>
          <p className="text-lg font-medium">Role: {profile.organization_role}</p>
          <p className="text-lg font-medium">Title: {profile.title}</p>
          <p className="text-lg font-medium">Gitlab Username: {profile?.gitlab_email}</p>
        </div>
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4">Commits</h2>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={commitData}>
              <XAxis dataKey="label_date" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="commits" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>


      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">All Commits</h2>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-300">
            <thead className="text-xs uppercase bg-gray-600">
              <tr>
                <th scope="col" className="px-6 py-3">Date</th>
                <th scope="col" className="px-6 py-3">Message</th>
                <th scope="col" className="px-6 py-3">Repository</th>
              </tr>
            </thead>
            <tbody>
              {sortedCommits.map((commit, index) => (
                <tr key={index} className="border-b bg-gray-800 border-gray-700">
                  <td className="px-6 py-4">{new Date(commit.created_at).toLocaleDateString()}</td>
                  <td className="px-6 py-4">{commit.commit_message}</td>
                  <td className="px-6 py-4">{commit.commit_project_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}