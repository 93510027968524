import { URL } from ".";
import toast from 'react-hot-toast';

const readOrg = async ({ token, org_id }) => {
  try {
    const response = await fetch(`${URL}/organizations/${org_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org');
  }
}

const readOrgTeams = async ({ token, org_id }) => {
  try {
    const response = await fetch(`${URL}/organizations/${org_id}/teams`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org teams');
  }
}

const readOrgMembers = async ({ token, org_id }) => {
  try {
    const response = await fetch(`${URL}/organizations/${org_id}/members`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org members');
  }
}

const readOrgCommits = async ({ token, org_id }) => {
  try {
    const response = await fetch(`${URL}/organizations/${org_id}/commits`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org commits');
  }
}

export { readOrgTeams, readOrg, readOrgCommits, readOrgMembers }