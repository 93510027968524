import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { signin, signup } from "../api/authentication";
import { useUserDispatch } from "../contexts/user";

const AuthForm = (props) => {
  const dispatch = useUserDispatch();
  const navigate = useNavigate();
  const [isSignupForm, setIsSignUpForm] = useState(props?.form === 'signup')
  const [userData, setUserData] = useState({
    email: "",
    password: ""
  });

  useEffect(() => {
    setIsSignUpForm(props?.form === 'signup')
  }, [props?.form])

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e, dispatch) => {
    e.preventDefault();
    try {
      let response;
      if (props?.form === "signin") {
        response = await signin({ email: userData.email, password: userData.password })
      } else if (props?.form === "signup") {
        response = await signup({ email: userData.email, password: userData.password })
      }

      if (!response?.data?.token) {
        throw new Error(`error signing ${props?.form === 'signin' ? 'in' : 'up'}`)
      }
      const { data } = response;
      dispatch({
        type: 'updated',
        user: { ...data }
      })
      navigate(`/home/${data?.id}`)
    } catch (error) {
      alert(error)
    }
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8 flex items-center justify-center">
      <div className="w-full max-w-md">
        <form onSubmit={(event) => { handleSubmit(event, dispatch) }} className="space-y-6">
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200">
                Email address
              </label>
              <input
                id="email"
                name="email"
                onChange={handleChange}
                value={userData.email}
                type="email"
                autoComplete="email"
                required
                className="mt-2 block w-full border-0 border-b-2 border-white bg-transparent py-1.5 text-gray-200 focus:border-indigo-600 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-200">
                Password
              </label>
              <input
                onChange={handleChange}
                value={userData.password}
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
                required
                className="mt-2 block w-full border-0 border-b-2 border-white bg-transparent py-1.5 text-gray-200 focus:border-indigo-600 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              onClick={() => navigate('/')}
              type="button"
              className="text-red-500 hover:underline py-2 px-4 text-sm font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex items-center text-gray-200 hover:underline py-2 px-4 text-sm font-medium"
            >
              {props?.buttonText || 'Go!'}
            </button>
          </div>
        </form>
        <p className="mt-6 text-xs justify-center flex leading-5 text-gray-400">
          {isSignupForm
            ? <>Already have an account?<span><Link className="pl-1 text-indigo-400 hover:underline" to="/signin"> Sign In</Link></span></>
            : <div className="text-center">
              <div>Need to sign up first?<span><Link className="pl-1 text-indigo-400 hover:underline" to="/signup"> Sign up</Link></span></div>
              <div>Forgot your password?<span><Link className="pl-1 text-indigo-400 hover:underline" to="/forgot_password"> Forgot Password</Link></span></div>
            </div>
          }
        </p>
      </div>
    </div>
  )
}

export default AuthForm;