import React, { useEffect, useState } from "react"
import { useUser } from "../contexts/user";
import { readOrgTeams, readOrg, readOrgCommits } from '../api/organizations';
import { deleteTeam } from '../api/teams';
import Loading from '../components/Loading';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { PlusIcon, PencilIcon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import TeamForm from '../components/TeamForm';
import moment from 'moment';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const Dropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-4 py-2 text-lg font-medium text-left text-white bg-gray-600 rounded-lg hover:bg-gray-500 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
      >
        <span>{title}</span>
        <ChevronDownIcon
          className={`${isOpen ? 'transform rotate-180' : ''} w-5 h-5 text-white`}
        />
      </button>
      {isOpen && (
        <div className="mt-2 p-4 bg-gray-800 rounded-lg">
          {children}
        </div>
      )}
    </div>
  );
};

export default function OrgPage() {
  const user = useUser();
  const navigate = useNavigate();
  const { org_id } = useParams()
  const [teams, setTeams] = useState([]);
  const [org, setOrg] = useState(null);
  const [commits, setCommits] = useState([]);
  const [commitsLoading, setCommitsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTeamForm, setShowTeamForm] = useState(false);
  const [editingTeam, setEditingTeam] = useState(null);
  const [commitsByDate, setCommitsByDate] = useState([]);

  const createTeamClicked = () => {
    setEditingTeam(null);
    setShowTeamForm(true);
  }
  const createMemberClicked = () => {
    navigate(`/org/${org_id}/members/new`);
  }
  const handleEditMember = (user_id) => {
    navigate(`/profile/${user_id}/update`);
  }

  const showOrgCommits = async () => {
    setCommitsLoading(true);
    try {
      const response = await readOrgCommits({
        token: user.token,
        org_id: user.organization,
      });
      if (response) {
        const fetchedCommits = response.data;
        setCommits(fetchedCommits);
        processCommitData(fetchedCommits);
      }
    } catch (error) {
      console.error('Failed to fetch commits', error);
    } finally {
      setCommitsLoading(false);
    }
  }

  const processCommitData = (fetchedCommits) => {
    const endDate = moment().endOf('day');
    const startDate = moment(endDate).subtract(6, 'days').startOf('day');

    // Create an array of the last 7 days
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      return moment(startDate).add(i, 'days').format('YYYY-MM-DD');
    });

    // Initialize commitsByDate with 0 commits for each day
    const commitCounts = last7Days.reduce((acc, date) => {
      acc[date] = 0;
      return acc;
    }, {});

    // Count commits for each day
    fetchedCommits.forEach(commit => {
      const commitDate = moment(commit.created_at).format('YYYY-MM-DD');
      if (commitDate in commitCounts) {
        commitCounts[commitDate]++;
      }
    });

    // Convert to array format for Recharts
    const newCommitsByDate = last7Days.map(date => ({
      date,
      label_date: moment(date).format('MM/DD'),
      commits: commitCounts[date]
    }));

    setCommitsByDate(newCommitsByDate);
  }

  const handleTeamSaved = (savedTeam) => {
    if (editingTeam) {
      setTeams(teams.map(team => team._id === savedTeam._id ? savedTeam : team));
    } else {
      setTeams([...teams, savedTeam]);
    }
    setShowTeamForm(false);
    setEditingTeam(null);
  }

  const handleEditTeam = (team) => {
    setEditingTeam(team);
    setShowTeamForm(true);
  }

  const handleDeleteTeam = async (team_id) => {
    const confirmed = window.confirm("Are you sure you want to delete this team?")
    if (!confirmed) return;

    const previousTeams = [...teams];
    setTeams(teams.filter(team => team._id !== team_id));

    try {
      const response = await deleteTeam({
        token: user.token,
        team_id,
      });
      if (response.ok) {
        const teams = await readOrgTeams({
          token: user.token,
          org_id: user.organization,
        })
        setTeams([...teams.data]);
      }
    } catch (error) {
      setTeams(previousTeams);
      console.error('Failed to delete team', error);
    }
    finally {
      setLoading(false)
    }
    console.log(`Successfully deleted team`);
  }

  useEffect(() => {
    if (user) {
      const fetchTeams = async () => {
        setLoading(true)
        try {
          const response = await readOrgTeams({
            token: user.token,
            org_id: user.organization,
          });
          if (response) {
            setTeams([...response.data]);
          }
        } catch (error) {
          console.error('Failed to fetch teams', error);
        }
        finally {
          setLoading(false)
        }
      };
      const fetchOrg = async () => {
        setLoading(true)
        try {
          const response = await readOrg({
            token: user.token,
            org_id: user.organization,
          });
          if (response) {
            setOrg({ ...response.data });
          }
        } catch (error) {
          console.error('Failed to fetch org', error);
        }
        finally {
          setLoading(false)
        }
      };

      fetchTeams();
      fetchOrg();
    }
  }, [user]);

  if (loading) {
    return <Loading />
  }

  if (showTeamForm) {
    return <TeamForm
      initialTeam={editingTeam}
      onTeamSaved={handleTeamSaved}
      onCancel={() => {
        setShowTeamForm(false);
        setEditingTeam(null);
      }}
    />;
  }

  return (
    <div className="min-h-screen bg-gray-700 text-white p-8">
      <div className="max-w-2xl mx-auto">
        <Dropdown title="Teams">
          <div className="space-y-2">
            {teams?.length > 0 ? (
              teams.map(team => (
                <div key={team._id} className="flex justify-between items-center py-2">
                  <Link to={`/org/${org_id}/teams/${team._id}`} className="text-gray-200 hover:text-gray-300 hover:underline">
                    {team.name}
                  </Link>
                  <div>
                    <button
                      onClick={() => handleEditTeam(team)}
                      className="text-gray-200 hover:text-gray-300 mr-3 transition-transform duration-200 ease-in-out hover:rotate-12"
                      aria-label={`Edit ${team.name}`}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDeleteTeam(team._id)}
                      className="text-red-500 hover:text-red-400 transition-transform duration-200 ease-in-out hover:rotate-90"
                      aria-label={`Delete ${team.name}`}
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No teams found.</p>
            )}
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={createTeamClicked}
              className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
              aria-label="Create new team"
            >
              <PlusIcon className='h-6 w-6' />
            </button>
          </div>
        </Dropdown>

        <Dropdown title="Members">
          <div className="space-y-2">
            {org?.members?.length > 0 ? (
              org.members.map(member => (
                <div key={member._id} className="flex justify-between items-center py-2">
                  <Link to={`/profile/${member._id}`} className="text-gray-200 hover:text-gray-300 hover:underline">
                    {member.name}
                  </Link>
                  <div>
                    <button
                      onClick={() => handleEditMember(member._id)}
                      className="text-gray-200 hover:text-gray-300 mr-3 transition-transform duration-200 ease-in-out hover:rotate-12"
                      aria-label={`Edit ${member.name}`}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-400 transition-transform duration-200 ease-in-out hover:rotate-90"
                      aria-label={`Delete ${member.name}`}
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No members found.</p>
            )}
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={createMemberClicked}
              className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
              aria-label="Create new member"
            >
              <PlusIcon className='h-6 w-6' />
            </button>
          </div>
        </Dropdown>

        <Dropdown title="Surveys">
          <div className="space-y-2">
            <p className="text-center">No surveys found.</p>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={() => navigate(`/org/${org_id}/surveys`)}
              className="text-white hover:rotate-90 transition-transform duration-300 focus:outline-none"
              aria-label="Create new survey"
            >
              <PlusIcon className='h-6 w-6' />
            </button>
          </div>
        </Dropdown>

        <Dropdown title={`Commits: (${commits.length})`}>
          <div className="space-y-4">
            <div className="w-full h-64">
              {commitsLoading ? <>loading</> :
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={commitsByDate}>
                    <XAxis dataKey="label_date" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="commits" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              }
            </div>
            <div className="space-y-2">
              {/* {commits.length > 0 ? (
                commits.map(commit => (
                  <div key={commit._id} className="flex justify-between items-center py-2">
                    <span className="text-gray-200">{commit.commit_message}</span>
                    <span className="text-gray-400">{moment(commit.created_at).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                ))
              ) : (
                <p className="text-center">No commits found.</p>
              )} */}
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={showOrgCommits}
              className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded"
              aria-label="Refresh commits"
            >
              Refresh Commits
            </button>
          </div>
        </Dropdown>
      </div>
    </div>
  )
}