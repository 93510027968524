import { URL } from ".";
import toast from 'react-hot-toast';


const createSurvey = async ({ token, survey }) => {
  try {
    const response = await fetch(`${URL}/surveys`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        survey
      })
    });
    return await response.json();
  } catch (error) {
    console.log('wow', { error })
    toast.error('Unable to create survey');
  }
}

const readSurvey = async ({ token, survey_id }) => {
  try {
    const response = await fetch(`${URL}/surveys/${survey_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read survey');
  }
}

const readOrgSurveys = async ({ token, org_id }) => {
  try {
    const response = await fetch(`${URL}/organizations/${org_id}/surveys`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    toast.error('Unable to read org surveys');
  }
}


export { readOrgSurveys, createSurvey, readSurvey }